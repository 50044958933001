import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { CssBaseline } from "@material-ui/core";

import ScrollButton from "./page_comps_main/components/buttons/ScrollButton";

import Header from "./page_comps_main/navbar/Header";
import Loading from "./auth/components/Loading";
import ProtectedRoute from "./auth/Protected-route";

import "./App.css";
import ActionDialog from "./page_comps_main/components/ActionDialog";
const Translation = React.lazy(() => import("./page_comps_main/translation/Translation"));
const Topic = React.lazy(() => import("./page_comps_main/topic/Topic"));
const Chapter = React.lazy(() => import("./page_comps_main/chapter/Chapter"));
const Add = React.lazy(() => import("./page_comps_main/protected/Add"));
const EditWord = React.lazy(() => import("./page_comps_main/protected/edit/EditWord"));
const EditChapter = React.lazy(() => import("./page_comps_main/protected/edit/EditChapter"));
const NotFound = React.lazy(() => import("./page_comps_main/components/NotFound"));

// TODO https://stackoverflow.com/questions/35604617/react-router-with-optional-path-parameter/70961421#70961421?newreg=77b6144507474367b5ca5c6ffb9cdcca
const App = () => {
  const [dictionary_no, setDictionary_no] = useState(null);
  const [words, setWords] = useState(null);
  const [lowestChapterID, setLowestChapterID] = useState(null);
  const [chapters, setChapters] = useState(null);

  //reloading data - when editing
  const [open, setOpen] = useState(false);

  //auth0
  const { loginWithRedirect, isLoading } = useAuth0();

  const reloadWords = async () => {
    try {
      const result = await (
        await fetch(process.env.REACT_APP_API_URL + "chapter_words_all.php?dictionary_no=" + dictionary_no)
      ).json();
      setWords(result);
    } catch (error) {
      console.log("error loading words! " + error);
    }
  };

  const reloadChapters = async () => {
    try {
      const result = await (
        await fetch(process.env.REACT_APP_API_URL + "chapter_list.php?dictionary_no=" + dictionary_no)
      ).json();
      setChapters(result);

      let allChapterIDs = result.map((chapter) => { return chapter.id })
      setLowestChapterID(Math.min(...allChapterIDs));
    } catch (error) {
      console.log("error loading chapters! " + error);
    }
  };

  useEffect(() => {
    if (dictionary_no != null) {
      reloadWords();
      reloadChapters();
    }
    // eslint-disable-next-line
  }, [dictionary_no]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <CssBaseline />
      <div className="main">
        <Header lowestChapterID={lowestChapterID} dictionary_no={dictionary_no} setDictionary_no={setDictionary_no} />
        <div className="main-content">
          <Switch>
            <Route
              exact
              path="/null/*"
              component={() => <Redirect to={"/1/topic/all"} />}
            />
            <Route
              path="/:id/translator/:word"
              component={() =>
                <React.Suspense fallback={"Načítání..."}>
                  <Translation
                    chapters={chapters}
                    dictionary_no={dictionary_no}
                    setDictionary_no={setDictionary_no}
                  />
                </React.Suspense>
              }
            />
            <Route
              path="/:id/translator"
              component={() =>
                <React.Suspense fallback={"Načítání..."}>
                  <Translation
                    chapters={chapters}
                    dictionary_no={dictionary_no}
                    setDictionary_no={setDictionary_no}
                  />
                </React.Suspense>
              }
            />
            <Route
              path="/:id/topic/:topic"
              component={() => (
                <React.Suspense fallback={"Načítání..."}>
                  <Topic
                    chapters={chapters}
                    words={words}
                    reloadWords={reloadWords}
                    dictionary_no={dictionary_no}
                    setDictionary_no={setDictionary_no}
                  />
                </React.Suspense>
              )}
            />
            <Route
              path="/:id/chapter"
              component={() => (
                <React.Suspense fallback={"Načítání..."}>
                  <Chapter
                    chapters={chapters}
                    reloadChapters={reloadChapters}
                    dictionary_no={dictionary_no}
                    setDictionary_no={setDictionary_no}
                  />
                </React.Suspense>
              )}
            />
            <Route path="/:id/login" component={() =>
              loginWithRedirect()
            }
            />
            <ProtectedRoute
              path="/:id/add"
              component={() => (
                <React.Suspense fallback={"Načítání..."}>
                  <Add
                    chapters={chapters}
                    reloadWords={() => reloadWords()}
                    reloadChapters={() => reloadChapters()}
                    open={() => {
                      setOpen(true);
                    }}
                    dictionary_no={dictionary_no}
                    setDictionary_no={setDictionary_no}
                  />
                </React.Suspense>
              )}
            />
            <ProtectedRoute
              path="/:id/edit_word/:wordid"
              component={() => (
                <React.Suspense fallback={"Načítání..."}>
                  <EditWord
                    chapters={chapters}
                    reloadWords={() => reloadWords()}
                    open={() => {
                      setOpen(true);
                    }}
                    dictionary_no={dictionary_no}
                    setDictionary_no={setDictionary_no}
                  />
                </React.Suspense>
              )}
            />
            <ProtectedRoute
              path="/:id/edit_chapter/:chapid"
              component={() => (
                <React.Suspense fallback={"Načítání..."}>
                  <EditChapter
                    chapters={chapters}
                    reloadChapters={() => reloadChapters()}
                    open={() => {
                      setOpen(true);
                    }}
                    dictionary_no={dictionary_no}
                    setDictionary_no={setDictionary_no}
                  />
                </React.Suspense>
              )}
            />
            <ProtectedRoute
              path="/:id/edit_chapter"
              component={() => (
                <React.Suspense fallback={"Načítání..."}>
                  <EditChapter
                    chapters={chapters}
                    reloadChapters={() => reloadChapters()}
                    open={() => {
                      setOpen(true);
                    }}
                    dictionary_no={dictionary_no}
                    setDictionary_no={setDictionary_no}
                  />
                </React.Suspense>
              )}
            />

            {/* default page here */}
            <Route
              exact
              path="/"
              component={() => <Redirect to={"/" + dictionary_no + "/topic/all"} />}
            />
            {/* when route is not found.. */}
            <Route
              exact
              path=""
              component={() =>
                <React.Suspense fallback={"Načítání..."}>
                  <NotFound to="/not_found" />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="*"
              component={() =>
                <React.Suspense fallback={"Načítání..."}>
                  <NotFound to="/not_found" />
                </React.Suspense>
              }
            />
          </Switch>
        </div>
      </div>
      <ActionDialog
        open={open}
        close={() => {
          setOpen(false);
        }}
      />
      <ScrollButton />
    </>
  );
};

export default App;
