import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import EjectIcon from "@material-ui/icons/Eject";
import "./ScrollButton.css";

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300 && !visible) {
      setVisible(true);
    } else if (scrolled <= 300 && visible) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <>
      <div className="iconTop">
        <Button
          variant="outlined"
          style={{ display: visible ? "inline" : "none" }}
          onClick={scrollToTop}
        >
          <EjectIcon className="ejectIcon" />
        </Button>
      </div>
    </>
  );
};

export default ScrollButton;
