import React, { useEffect, useState } from "react";
import {
  AppBar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import Divider from "@mui/material/Divider";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import LogoutButton from "./components/Logout-button";
import LoginButton from "./components/Login-button";
import DictionarySwitcher from "./components/DictionarySwitcher";
import logo from "../components/logo_lfhk.png";
import MenuIcon from "@material-ui/icons/Menu";
import "../../App.css";

export default function Header ({ lowestChapterID, dictionary_no, setDictionary_no }) {

  const [anchorEl, setAnchorEl] = useState(null);
  const [dictionaryText, setDictionaryText] = useState("Neznámý slovník");
  const [facultyText, setFacultyText] = useState("Neznámá fakulta");
  const [customColor, setCustomColor] = useState('#009900'); //default value
  
  const { logout } = useAuth0();
  const { loginWithRedirect } = useAuth0();
  const { isAuthenticated } = useAuth0();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect (() => {
    switch (parseInt(dictionary_no)) {
      case 0:
        setDictionaryText("Slovník vývojáře");
        setFacultyText("Faculty of memes, internet");
        setCustomColor('#a9a9a9');
        break;
      case 1:
        setDictionaryText("Lékařský slovník pro stomatology");
        setFacultyText("Lékařská fakulta Univerzity Karlovy v Hradci Králové");
        setCustomColor('');
        break;
      case 2:
        setDictionaryText("Lékařský slovník pro všeobecné lékařství");
        setFacultyText("Lékařská fakulta Univerzity Karlovy v Hradci Králové");
        setCustomColor('#009900');
        break;
      default:
        setDictionaryText("Nespecifikovaný slovník");
        setFacultyText("");
        break;
    }
  }, [dictionary_no]);

  return (
    <div className="header">
      <AppBar style={{ background: customColor }} position="static">
        <div className="toolbar">
          <div className="headerRow">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleClick}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <Link className="link" to={"/"+dictionary_no+"/topic/all"}>
                <MenuItem onClick={handleClose}>Celý slovník</MenuItem>
              </Link>
              <Divider sx={{ my: 0.5 }} />
              <Link className="link" to={"/"+dictionary_no+"/translator"}>
                <MenuItem onClick={handleClose}>Překladač</MenuItem>
              </Link>
              <Link className="link" to={"/"+dictionary_no+"/topic/"+lowestChapterID}>
                <MenuItem onClick={handleClose}>Kapitoly</MenuItem>
              </Link>
              <Link className="link" to={"/"+dictionary_no+"/chapter"}>
                <MenuItem onClick={handleClose}>Témata</MenuItem>
              </Link>
              <Divider sx={{ my: 0.5 }} />
              {!isAuthenticated && (
                <MenuItem className="link hiddenUnder1000" onClick={() => loginWithRedirect()}>Přihlásit se</MenuItem>
              )}
              {isAuthenticated && (
                <MenuItem className="link hiddenUnder1000" onClick={() => logout({returnTo: window.location.origin })}>Odhlásit se</MenuItem>
              )}
              {isAuthenticated && (
                <Link className="link" to={"/"+dictionary_no+"/add"}>
                  <MenuItem onClick={handleClose}>Přidej..</MenuItem>
                </Link>
              )}
              {isAuthenticated && (
                <Link className="link" to={"/"+dictionary_no+"/edit_chapter"}>
                  <MenuItem onClick={handleClose}>Změň název</MenuItem>
                </Link>
              )}
            </Menu>
            <Link className="link hiddenUnder1000" to={"/"+dictionary_no+"/topic/all"}>
              <img
                src={logo}
                alt="Logo Lékařská fakulta Univerzity Karlovy v Hradci Králové"
                style={{
                  width: "120px",
                  height: "120px",
                }}
                className="logo"
              />
            </Link>
            <div className="headerText">
              <Typography noWrap variant="h5" className="title title-dictionary">
              <span>{dictionaryText}</span>
              </Typography>
              <Typography noWrap variant="h5" className="title title-faculty">
                <span>{facultyText}</span>
              </Typography>
            </div>
          </div>
          <div className="logout">
            <DictionarySwitcher dictionary_no={dictionary_no} setDictionary_no={setDictionary_no} />
            <span className="hiddenUnder1000">
            {isAuthenticated ? <LogoutButton /> : <></>}
            {!isAuthenticated ? <LoginButton /> : <></>}
            </span>
          </div>
        </div>
      </AppBar>
    </div>
  );
};
