import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
/* import Slide from "@mui/material/Slide"; */

/* const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
}); */

export default function ActionDialog({ open, close }) {
  return (
    <Dialog
      open={open}
      /* TransitionComponent={Transition} */
      /* keepMounted */
      onClose={close}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">Úspěch!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Akce proběhla úspěšně.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
