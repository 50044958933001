import React, { useState } from "react";
import { Menu, MenuItem } from "@material-ui/core";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Button } from "@material-ui/core";
import "../../../App.css";
import { Link } from "react-router-dom";

const DictionarySwitcher = ({dictionary_no, setDictionary_no}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    function dictionaryRedirect (switchToDictionary) {
        setDictionary_no(switchToDictionary);
    }

    return (
        <>
            <Button
                variant="contained"
                onClick={handleClick}
            >
                <MenuBookIcon />
                &nbsp;Změň slovník
            </Button>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    Number(dictionary_no) === 1 ? 
                    <MenuItem disabled>Stomatologové</MenuItem> : 
                    <MenuItem onClick={() => {dictionaryRedirect(1); handleClose(); }}><Link className="link" to="/1/topic/all">Stomatologové</Link></MenuItem> 
                }       
                {
                    Number(dictionary_no) === 2 ? 
                    <MenuItem disabled>Všeobecné lékařství</MenuItem> : 
                    <MenuItem onClick={() => {dictionaryRedirect(2); handleClose(); }}><Link className="link" to="/2/topic/all">Všeobecné lékařství</Link></MenuItem> 
                }
            </Menu>
        </>
    );
};

export default DictionarySwitcher;